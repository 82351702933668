import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Nav from 'react-bootstrap/Nav' ;
import Logo from '../../static/vms_dtp_logo.svg';
import MyVerticallyCenteredModal  from './Modal';
import FooterPage from './Footer';
import '../../static/index.css';
import img_1 from '../../static/office-img-1.jpg';
import img_2 from '../../static/office-img-2.jpg';
import { FaPhone } from 'react-icons/fa';
import { FaMailBulk } from 'react-icons/fa';
import { FaGlobeAsia } from 'react-icons/fa';
import LoaderSVG from '../../static/loader.svg';
import {jsx, css} from '@emotion/react'


export default () => {
const [modalShow, setModalShow] = React.useState(false);
const breakpoints = [576, 768, 992, 1200]

const mq = breakpoints.map(
  bp => `@media (max-width: ${bp}px)`
)
return (
    <div>
  <div>
  
   <Navbar collapseOnSelect expand="md" bg="light" variant="light">
  <Navbar.Brand href="/">
      <img
        src={Logo}
        width="191"
        height="49.5"
        className="d-inline-block align-top"
        alt="vms dtp logo"
      />
    </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="ml-auto">
    {/* <Nav.Link href="http://localhost:8000">Home</Nav.Link> */}
    <Nav.Link href="/">  Home</Nav.Link>
           <Nav.Link href="/services">Services</Nav.Link>
      <Nav.Link href="#"> Contact  </Nav.Link>
      
    </Nav>
  </Navbar.Collapse>
</Navbar>
 
  <br/>
   
    </div>
  
    <div
    key={`loader`}
              id="___loader"
            css={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FFF",
                justifyContent: "center",
                height:600,
                zIndex: 100,
                [mq[0]]: {
                  height:100,
                },
                [mq[1]]: {
                  height:200,
                },
                [mq[2]]: {
                  height:500,
                },
               }}     >
              <img 
                src={LoaderSVG} 
                alt="loading spinner" 
                width="25" 
                height="25"
              />
        </div>
    <Carousel id="my_id" style={{display:"none"}}>
  <Carousel.Item>
    <img
      className="image-responsive d-block w-100"
      src={img_1}
      alt="office-img-1"
    />
    <Carousel.Caption>
      <h3>Greetings from VMS DTP</h3>
      <p>Call us on +91 xxxxxxxxxx</p>
    </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="image-responsive d-block w-100"
      src={img_2}
      alt="office-img-2"
    />

    <Carousel.Caption>
      <h3>Greetings from VMS DTP</h3>
      <p>Call us on +91 xxxxxxxxxx</p>
    </Carousel.Caption>
  </Carousel.Item>
  
</Carousel>
  <br/>
  
<Container>
  <Row>
    <Col>
<h3 className="contact-h3">Contact Us: </h3>
<h5 className="contact-h5"> <FaPhone /> +91 xxxxxxxxxx</h5>
<h5 className="contact-h5"><FaMailBulk /> vinod.mathew.sebastian@gmail.com</h5>
<h5 className="contact-h5"><FaGlobeAsia /> https://vmsdtp.netlify.app</h5>
    </Col>
  </Row>
</Container>



   <FooterPage />
</div> );
}

